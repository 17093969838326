import { FAQ_LANDING_REQUEST_SUCCESS, FAQ_LANDING_REQUEST, FAQ_LANDING_REQUEST_FAIL } from "../../Constant/Configuration/FAQLandingConstan";


export const getFAQLandingReducer = (state = { faqLanding: [], loading: false }, action) => {

    switch (action.type) {
        case FAQ_LANDING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FAQ_LANDING_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                faqLanding: action.payload
            }

        case FAQ_LANDING_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }


};
