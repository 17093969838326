
import {
    STR_OBJ_DETAILS_REQUEST,
    STR_OBJ_DETAILS_SUCCESS,
    STR_OBJ_DETAILS_FAIL,
} from "./StrObjDetailsConstant";

export const GetStrObjDetailsReducer = (state = { strobjDetails: {}, loading: false, }, action) => {
    switch (action.type) {

        case STR_OBJ_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };


        case STR_OBJ_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                strobjDetails: action.payload,
            };
        case STR_OBJ_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state;
    }
};
