import { HASH_TAG_REQUEST, HASH_TAG_SUCCESS, HASH_TAG_FAIL } from "./HashTagConstant";



export const hashTagReducer = (state = { hashTagData: [], loading: false }, action) => {

    switch (action.type) {
        case HASH_TAG_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case HASH_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                hashTagData: action.payload,
            }


        case HASH_TAG_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }

}



