import { PUBLICATION_LANDING_REQUEST, PUBLICATION_LANDING_SUCCESS, PUBLICATION_LANDING_FAIL } from "../../Constant/Publication/PublicationLandingConstant";

export const getPublicationLandingReducer = (state = { publicationLanding: [], loading: false }, action) => {
  switch (action.type) {

    case PUBLICATION_LANDING_REQUEST:
      return {
        ...state,
        loading: true,
      };


    case PUBLICATION_LANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        publicationLanding: action.payload,
      };
    case PUBLICATION_LANDING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state;
  }
};
