import { NOTICE_WITH_OUT_AUTH_REQUEST, NOTICE_WITH_OUT_AUTH_SUCCESS, NOTICE_WITH_OUT_AUTH_FAIL } from "../../../Constant/Notice/Notice/NoticeWithOutAuthConstant";

export const getNoticeWithOutAuthReducer = (state = { noticeWithOutAuth: [],loading:false }, action) => {
  switch (action.type) {
    case NOTICE_WITH_OUT_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTICE_WITH_OUT_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        noticeWithOutAuth: action.payload,
      };
    case NOTICE_WITH_OUT_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state;
  }
};
